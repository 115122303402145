:root {
  /* secondary color*/
  --primary-color: #274ecb;

  /* secondary color*/
  --secondary-color: #f5bf13;

  --subheading-color: #808080;
  /* ligher shade of blue */
  --light-blue-color: #f6f8fe;

  /* darker shade of blue */
  --dark-blue-color: #aebaf4;

  /* green color used for success */
  --dark-green-color: #008000;

  /* border color */
  --border-color: #dbdcdf;

  /* form background color */
  --form-color: #f5f5f5;

  /* used for edit button color */
  --blue-color: #3d5ccb;

  /* used for delete button color */
  --red-color: #ff4d4f;

  /* used for title color */
  --midnight-blue: #27303c;

  --white-color: #ffffff;

  --black-color: #000000;
  --subheading: #e6e6e6;
  --titleColor: #858585;
  --menuTitleColor: #333333;
  --selectPlaceholder: #908c89;
  --table-header-bg-color: #4e75d9;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} 

/* start noto-sans font-family heading size */

h1 {
  font-size: 42px;
  line-height: 65px;
}

h2 {
  font-size: 34px;
  line-height: 52px;
}

h3 {
  font-size: 26px;
  line-height: 42px;
}

h4 {
  font-size: 20px;
  line-height: 33px;
}

h5 {
  font-size: 16px;
  line-height: 27px;
}

h6 {
  font-size: 14px;
  line-height: 23px;
}

/* end noto-sans font family heading size */
.ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: var(--menuTitleColor) !important;
}

.ant-menu-submenu-title:hover {
  color: var(--black-color) !important;
}

.a8-disabled-button {
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none !important;
}

.a8-delete-button {
  color: var(--red-color) !important;
  border-color: var(--red-color) !important;
}

.a8-error {
  color: var(--red-color) !important;
  border-color: var(--red-color) !important;
}

.a8-title {
  color: var(--midnight-blue) !important;
  font-size: 24px !important;
  text-align: center;
  font-weight: 600;
}

.a8-bg-white {
  background: var(--white-color) !important;
}

.a8-subheading {
  color: var(--subheading-color) !important;
}

.a8-black {
  color: var(--black-color);
}

.a8-action-table table tr > th:last-child,
.a8-action-table table tr > td:last-child {
  width: 200px;
}

/* Moved required asterick from left to right for fields */
.ant-form-item-required {
  position: relative;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  position: absolute;
  right: -3px;
}

h1.a8-page-title,
h2.a8-page-title {
  background: var(--midnight-blue) !important;
  font-size: 24px !important;
  text-align: center;
  font-weight: 600;
  padding: 18px 36px;
  color: var(--white-color);
  border-radius: 50px;
}

.ant-page-header .ant-page-header-heading {
  padding: 8px 16px;
  background: var(--subheading);
  border-radius: 56px 56px;
  margin-bottom: 12px;
}

.ant-btn.ant-btn-lg.ant-btn-icon-only {
  background: var(--white-color);
  border-radius: 24px;
}

.ant-page-header-back-button .ant-btn.ant-btn-lg.ant-btn-icon-only:hover {
  background: var(--secondary-color);
  border-radius: 24px;
}

.ant-page-header-heading-title {
  color: var(--white-color) !important;
}

.floating-label {
  /* position: absolute; */
  top: 0;
  z-index: 10;
  background: var(--white-color);
  /* margin-left: 16px; */
  margin-top: -12px;
  padding: 0px 4px;
  font-weight: 600;
  color: var(--black-color);
}

.custom-dropdown .ant-select-selector {
  padding: 25px 12px !important;
  border-radius: 8px !important;
}

.custom-dropdown .ant-form-item-control-input-content {
  padding-bottom: 0.75rem !important;
}

.multi-custom-dropdown .ant-select-selector {
  padding: 6px 6px !important;
  border-radius: 8px !important;
}

.refine-create-button {
  background: var(--white-color) !important;
  border-radius: 24px !important;
}

.refine-edit-button {
  background: var(--white-color) !important;
  color: var(--black-color) !important;
}

/* Sider mockup css  */
.ant-menu-item.ant-menu-item-selected {
  border-radius: 16px 0px 0px 16px;
  margin-inline: 0px;
  margin-block: 0px;
  width: unset;
  margin-left: 10px;
}

.ant-menu-item {
  margin-inline: 0px !important;
  margin-block: 0px !important;
  /* width: unset !important; */
}

/* **************** */
.ant-menu {
  /* background: var(--midnight-blue); */
  background-color: var(--subheading);
  padding: 40px 0 40px 20px;
  list-style-type: none;
  position: relative;
  overflow: hidden !important;
}

.ant-menu > li {
  position: relative;
}

.ant-menu > li::before,
.ant-menu > li::after {
  content: '';
  position: absolute;
}

.ant-menu-item {
  overflow: visible !important;
}

.ant-menu > .ant-menu-item-selected::before {
  top: -60px !important;
  right: -6px !important;
  height: 60px !important;
  width: 50px !important;
  border-bottom-right-radius: 42px !important;
  box-shadow: 0 40px 0 0 var(--form-color) !important;
}

.ant-menu > .ant-menu-item-selected::after {
  top: 40px !important;
  right: -5px !important;
  height: 80px !important;
  width: 50px !important;
  border-top-right-radius: 42px !important;
  box-shadow: 0 -40px 0 0 var(--form-color) !important;
  transition: unset !important;
}

.ant-menu {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* sider submenu pop up css start */
.ant-menu-submenu.ant-menu-submenu-popup .ant-menu-item:hover {
  border-radius: unset !important;
}

.ant-menu-submenu.ant-menu-submenu-popup .ant-menu-item:hover::after {
  box-shadow: unset !important;
  background-color: unset !important;
}

.ant-menu-submenu.ant-menu-submenu-popup .ant-menu-item:hover::before {
  box-shadow: unset !important;
  background-color: unset !important;
}

.ant-menu-submenu.ant-menu-submenu-popup .ant-menu-item-selected::after {
  border-top-right-radius: unset !important;
  box-shadow: unset !important;
}

.ant-menu-submenu.ant-menu-submenu-popup .ant-menu-item-selected::before {
  border-top-right-radius: unset !important;
  box-shadow: unset !important;
}

.ant-menu-submenu.ant-menu-submenu-popup {
  width: fit-content !important;
}

/* sider submenu pop up css end */
.ant-menu-item {
  overflow: visible !important;
  transition: unset !important;
}

.ant-menu-item:hover {
  /* border-radius: 16px 0px 0px 16px !important; */
}

.ant-menu-item:active {
  background-color: var(--form-color) !important;
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  overflow: visible !important;
}

.ant-menu-submenu {
  padding: 0px !important;
}

.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected,
.ant-menu-submenu.ant-menu-submenu-vertical .ant-menu-submenu-title:hover {
  background-color: var(--white-color);
  border-radius: 16px 0px 0px 16px;
}

.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: var(--black-color) !important;
}

.bg-badge {
  background: var(--subheading);
  color: var(--menuTitleColor);
}

.ant-table-thead > tr th {
  background-color: var(--table-header-bg-color) !important;
  color: var(--white-color) !important;
  font-size: 17px;
  /* font-family:Arial ; */
}

.ant-input-affix-wrapper {
  border-radius: 20px !important;
}

.border-radius-20 {
  border-radius: 20px;
}

.add-custom-column .ant-space-item > button > span {
  font-size: 16px !important;
}

.card-style {
  padding: 0px !important;
  background-color: var(--subheading);
  border-radius: 50px;
}

.ant-btn.ant-btn-lg {
  border-radius: 24px !important;
}

.ant-card.card-style .ant-card-body {
  padding: 8px 24px !important;
  border-radius: 0 0 8px 8px;
}

.ant-layout-sider-trigger > button {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}

.unified .ant-btn.ant-btn-lg {
  background: var(--white-color) !important;
  border: 1px solid var(--border-color);
}

.ant-layout-sider-trigger .ant-btn.ant-btn-lg {
  border-radius: 0px !important;
}

/* file upload list delete icon css start */
.ant-btn-icon .anticon.anticon-delete {
  color: var(--red-color) !important;
  font-size: 24px !important;
}
/* list delete icon disabled */
.a8-disabled-button .ant-btn-icon .anticon.anticon-delete {
  color: rgba(0, 0, 0, 0.25) !important;
  font-size: 20px !important;
}
.ant-upload-list-item-actions.picture {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* file upload list delete icon css end */

/* join column table header background first child css */
.join-column-table-parent .ant-table-container .ant-table-thead td {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}

.cancel-button.ant-btn.ant-btn-lg {
  border-color: var(--primary-color);
}

.delete-icon-size {
  font-size: 20px !important;
}

.custom-dropdown .ant-select-arrow {
  margin-top: 0px !important;
}

.custom-border .ant-input-affix-wrapper {
  border-radius: 8px !important;
}

.ant-picker {
  width: 100%;
}

.isActive-button-parent .ant-select-selector {
  padding: 12px 12px !important;
  border-radius: 20px !important;
}

.isActive-button-parent .ant-select-arrow {
  margin-top: -5px !important;
}

.custom-border .ant-input-affix-wrapper {
  border-radius: 8px !important;
}

.ant-checkbox-inner {
  width: 24px !important;
  height: 24px !important;
}

.ant-checkbox-inner::after {
  height: 12px !important;
  width: 6px !important;
  transform: rotate(36deg) scale(1) translate(-50%, -50%) !important;
  inset-inline-start: 34% !important;
  border-width: 3px !important;
}

.multilingual.ant-dropdown-trigger {
  border: 1px solid var(--subheading);
  padding: 8px;
  border-radius: 8px;
  margin: 0px 4px;
}

.org-select .ant-select-arrow {
  margin-top: -5px !important;
}

/* ant spinner */
.ant-spin.ant-spin-spinning {
  background-color: rgba(189, 189, 189, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-menu-inline .ant-menu-submenu-title {
  margin-inline: 0px !important;
}

aside {
  border-right: unset !important;
}

/* cancel btn css */
.cancel-button.ant-btn.ant-btn-lg {
  border-color: var(--primary-color);
}

/* pagination active css */
.ant-pagination .ant-pagination-item-active {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.ant-menu .ant-menu-item {
  margin-bottom: 12px !important;
}

.ant-menu-item:not(.ant-menu-item-selected):hover {
  border: 1px solid var(--primary-color);
}

.ant-layout-sider-children > div {
  border-bottom: none !important;
}

.ant-table-cell::before {
  background-color: unset !important;
}

.ant-select-selection-placeholder {
  color: var(--selectPlaceholder) !important;
}

.floating-disabled .floating-label {
  background: transparent !important;
}

.floating-disabled .ant-input-disabled,
.floating-disabled .bodmas-input,
.floating-disabled .ant-select-selector {
  border: unset !important;
}

/* aside style start */
.ant-layout aside.ant-layout-sider .ant-layout-sider-children {
  height: calc(100vh - 46px);
  position: sticky;
  top: 0;
  margin-top: 0;
}

.ant-layout aside.ant-layout-sider .ant-layout-sider-children .ant-menu {
  height: calc(100% - 66px) !important;
  border-radius: 0;
  position: sticky;
  top: 64px;
}

/* aside style end */
.custom-textarea-input {
  padding-top: 10px !important;
}

.checkbox-parent.select-all-checkbox .ant-checkbox .ant-checkbox-inner:after {
  border: 2px solid var(--primary-color);
  border-top: 0;
  border-left: 0;
}

.checkbox-parent.select-all-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--white-color);
  border-color: var(--white-color);
}

.custom-textarea-input {
  color: var(--black-color) !important;
}

.card-style {
  padding: 0px !important;
  background-color: var(--subheading);
  border-radius: 50px;
  height: 65px;
}

.card-title {
  margin: 0px;
  font-weight: 700;
  color: var(--menuTitleColor);
  font-size: 22px;
}

.ant-card.card-style .ant-card-body {
  padding: 8px 24px !important;
  border-radius: 0 0 8px 8px;
  width: 100%;
}

.card-style .ant-btn-primary {
  background: var(--white-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

/*for noticaition */

#messages {
  animation: 1s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes fadeIn {
  50% {
    visibility: hidden;
  }

  60% {
    visibility: visible;
    opacity: 0.5;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}
.floating-label + .ant-form-item {
  margin-top: 8px;
}

.element-sm .floating-label {
  font-size: 14px;
}
.ant-form-item
  .ant-input-affix-wrapper.ant-input-affix-wrapper-sm
  > input.ant-input {
  font-size: 14px;
}

.ant-form-item .ant-form-item-explain .ant-form-item-explain-error,
.ant-form-item .ant-form-item-extra .ant-form-item-explain-error {
  font-size: 14px;
}

